/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, Title, Subtitle, Text, Button, Fullmap, FullmapWrap, FullmapCover } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Půjčovna Lodě Skryje"}>
        <SiteHeader set="cr6jmhtsgae" currentLanguage={undefined} />

        <Column className="pb--60 pt--60" style={{"backgroundColor":"#70e8e1"}} name={"t9kp7q7mz7b"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Image className="--left" style={{"maxWidth":1000}} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/1438/b6f62470b51e4b97bed9115b8fdb41a2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/1438/b6f62470b51e4b97bed9115b8fdb41a2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/1438/b6f62470b51e4b97bed9115b8fdb41a2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/1438/b6f62470b51e4b97bed9115b8fdb41a2_s=1400x_.jpg 1400w"} alt={"půjčovna lodí Berounka"} src={"https://cdn.swbpg.com/t/1438/b6f62470b51e4b97bed9115b8fdb41a2_s=860x_.jpg"} lightbox={false}>
              </Image>

              <Title className="title-box title-box--center fs--26" style={{"maxWidth":1000}} content={"<span style=\"color: var(--black);\"><br>&nbsp; &nbsp; &nbsp; <span style=\"font-weight: bold; font-style: italic;\">Půjčovna kánoí a raftů na Berounce&nbsp;</span></span>"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--center fs--16" content={"<span style=\"font-style: italic;\">Cena je za plavidlo s vybavením.&nbsp;</span>Půjčovna se nachází poblíž obce Skryje,<span style=\"font-style: italic;\"><br>výdejní místa jsou po celém úseku řeky od Chrástu po Račice.&nbsp;<br></span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"hiopov05hjg"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper className="--center" style={{"maxWidth":480}}>
              
              <Title className="title-box ff--2 fs--18 w--500 ls--08 lh--14" style={{"paddingRight":0}} content={"<span style=\"color: rgb(8, 6, 0); font-style: italic; font-weight: bold;\">vybavení, ceník a mapa&nbsp;</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" style={{"backgroundColor":"#70e8e1"}} name={"5lloodlaxg"} layout={"l9"}>
          
          <ColumnWrap className="column__flex el--2" columns={"2"}>
            
            <ColumnWrapper className="--center">
              
              <Image src={"https://cdn.swbpg.com/t/1438/1786fe1519ef4567b930346726ae325c_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/1438/1786fe1519ef4567b930346726ae325c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/1438/1786fe1519ef4567b930346726ae325c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/1438/1786fe1519ef4567b930346726ae325c_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Image src={"https://cdn.swbpg.com/t/1438/dd3ef97de5874d78a0f3511d51ca792a_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/1438/dd3ef97de5874d78a0f3511d51ca792a_s=350x_.png 350w, https://cdn.swbpg.com/t/1438/dd3ef97de5874d78a0f3511d51ca792a_s=660x_.png 660w, https://cdn.swbpg.com/t/1438/dd3ef97de5874d78a0f3511d51ca792a_s=860x_.png 860w"}>
              </Image>

              <Text className="text-box text-box--center fs--18 w--600 mt--06" content={"<span style=\"font-weight: normal; font-style: italic;\">Po dohodě možnost změny délky a trasy plavby\núschova bagáže zdarma, převoz mezi kempy 900 Kč/1 den\ndoprava osob po domluvě\ndoprava v ceně pro kánoe a rafty. Paddleboardů po dohodě\n\nVýdejní a svozová místa: Chrást, Liblín, Zvíkovec, Č. mlýn, Skryje, Rozvědčík, Višňová, Roztoky, Zbečno, Račice</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" style={{"backgroundColor":"#70e8e1"}} name={"jxavfellas"} layout={"l2"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--30 w--600 ls--04 lh--14" content={"<span style=\"color: rgb(4, 3, 0); text-decoration-line: underline;\">Objednávka</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"<span style=\"font-style: italic; font-weight: bold; text-decoration-line: underline;\">1 denní plavba</span>"}>
              </Subtitle>

              <Text className="text-box" content={"krajinou Oty Pavla ze Skryjí do Roztok,&nbsp;<br>pohodový výlet, cca 4 hod plavby<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold; font-style: italic; text-decoration-line: underline;\">2 denní plavba</span>"}>
              </Subtitle>

              <Text className="text-box" content={"Zvíkovec, Skryje - Zbečno, nebo Liblín až Skryje"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold; font-style: italic; text-decoration-line: underline;\">3 a více denní plavba</span>"}>
              </Subtitle>

              <Text className="text-box" content={"Chrást až Skryje nebo Račice&nbsp;"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Button className="btn-box btn-box--center ff--1 fs--24 pl--06 pt--0" style={{"backgroundColor":"rgba(19,117,244,1)"}} content={"<span style=\"font-weight: bold;\">Objednat</span>"} url={"https://objednavka.lodeskryje.cz/"} href={"https://objednavka.lodeskryje.cz/"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" style={{"backgroundColor":"#70e8e1"}} name={"zcxp339xban"} layout={"l13"}>
          
          <ColumnWrap className="column__flex el--2 flex--stretch" columns={"2"}>
            
            <ColumnWrapper className="--center">
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--black); font-weight: bold;\">O řece Berounce</span><br>"}>
              </Subtitle>

              <Text className="text-box w--900" content={"<span style=\"font-style: italic; font-weight: normal;\">Tato nejstarší česká řeka je klidná, proto je vhodná pro začínající<br>vodáky a pohodáře, kteří nikam nespěchají. Úsek řeky na Rakovnicku je nejkrásnějším na celém toku a protéká hlubokým údolím napříč krajinou Křivoklátska. Nachází se zde trosky hradu Týřova, Čertova&nbsp; skála, vesnice Skryje s muzeem Joachima Barranda, malebné údolí Zbirožského potoka s romantickými jezírky. Možnost návštěvy elektroskanzenu Čechův mlýn ve Šlovicích&nbsp; a hradu Křivoklát. Mezi Nezabudicemi a Branovem řeka protéká údolím Zlatých úhořů, známých z povídek Oty Pavla. Expozici věnovanou životu a dílu Oty Pavla najdete hned u řeky v domě převozníka Proška v Luhu u Branova. Zastavit se můžete ve vyhlášeném&nbsp; hostinci Rozvědčík.</span><span style=\"color: rgb(225, 145, 12);\"></span><br>"}>
              </Text>

              <Subtitle className="subtitle-box subtitle-box--style1 ff--2" content={"<span style=\"color: var(--black); font-weight: bold;\">Sjízdnost řeky</span><br>"}>
              </Subtitle>

              <Text className="text-box w--900" content={"<span style=\"font-weight: normal;\">Berounka je poměrně vodnatá řeka, sjízdná z Plzně téměř po celý rok. Vzhledem k malému spádu, množství jezů a řečiště bez balvanovitých polí ji lze bezpečně sjíždět i za nižších vodních stavů. <br>Rozvrh plavby - délka trasy, jakou jste schopni za jeden den splout je velice individuální. Záleží nejen na stavu vody, počasí, zkušeností, ale i délky a počtu zastávek. Průměrná cestovní rychlost je 4-5 km/h</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Subtitle className="subtitle-box" content={"<br>Ze Skryjského mostu"}>
              </Subtitle>

              <Image src={"https://cdn.swbpg.com/t/1438/00e218f910f54b1cad77765a01895008_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/1438/00e218f910f54b1cad77765a01895008_s=350x_.png 350w, https://cdn.swbpg.com/t/1438/00e218f910f54b1cad77765a01895008_s=660x_.png 660w, https://cdn.swbpg.com/t/1438/00e218f910f54b1cad77765a01895008_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"norblz8wdz"} layout={"l8"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--center">
              
              <Subtitle className="subtitle-box fs--20" content={"Zvíkovec<br><br>"}>
              </Subtitle>

              <Image src={"https://cdn.swbpg.com/t/1438/cfce5890963c4c4cb02af0d50f7b1651_s=860x_.JPG"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/1438/cfce5890963c4c4cb02af0d50f7b1651_s=350x_.JPG 350w, https://cdn.swbpg.com/t/1438/cfce5890963c4c4cb02af0d50f7b1651_s=660x_.JPG 660w, https://cdn.swbpg.com/t/1438/cfce5890963c4c4cb02af0d50f7b1651_s=860x_.JPG 860w, https://cdn.swbpg.com/t/1438/cfce5890963c4c4cb02af0d50f7b1651_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/1438/cfce5890963c4c4cb02af0d50f7b1651_s=2000x_.JPG 2000w"} position={{"x":-6,"y":1}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box fs--20" content={"jez Čilá<br><br>"}>
              </Subtitle>

              <Image src={"https://cdn.swbpg.com/t/1438/731935fd3ec344ec9fec8a7c8eb15274_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/1438/731935fd3ec344ec9fec8a7c8eb15274_s=350x_.jpg 350w, https://cdn.swbpg.com/t/1438/731935fd3ec344ec9fec8a7c8eb15274_s=660x_.jpg 660w, https://cdn.swbpg.com/t/1438/731935fd3ec344ec9fec8a7c8eb15274_s=860x_.jpg 860w, https://cdn.swbpg.com/t/1438/731935fd3ec344ec9fec8a7c8eb15274_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/1438/731935fd3ec344ec9fec8a7c8eb15274_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/1438/6ddfbaf94c3f40a3bf91790f7fa06d4d_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/1438/6ddfbaf94c3f40a3bf91790f7fa06d4d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/1438/6ddfbaf94c3f40a3bf91790f7fa06d4d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/1438/6ddfbaf94c3f40a3bf91790f7fa06d4d_s=860x_.jpg 860w, https://cdn.swbpg.com/t/1438/6ddfbaf94c3f40a3bf91790f7fa06d4d_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/1438/6ddfbaf94c3f40a3bf91790f7fa06d4d_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box" content={"<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/1438/524248f9afc84f6692d228f8b7cbf11a_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/1438/524248f9afc84f6692d228f8b7cbf11a_s=350x_.jpg 350w, https://cdn.swbpg.com/t/1438/524248f9afc84f6692d228f8b7cbf11a_s=660x_.jpg 660w, https://cdn.swbpg.com/t/1438/524248f9afc84f6692d228f8b7cbf11a_s=860x_.jpg 860w, https://cdn.swbpg.com/t/1438/524248f9afc84f6692d228f8b7cbf11a_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap name={"vse93sn2sd"}>
          
          <FullmapWrap className="pb--20 pt--20">
            
            <Subtitle className="subtitle-box subtitle-box--center fs--36" content={"<span style=\"font-weight: bold;\">Kontakt</span>"}>
            </Subtitle>

          </FullmapWrap>

          <FullmapWrap className="--full">
            
            <Text className="text-box text-box--center fs--20" content={"<span style=\"font-weight: bold;\">Martin Mach&nbsp; Šlovice 9<br>tel. 777 083 538&nbsp;</span><br><span style=\"font-weight: bold;\">&nbsp; &nbsp; lodeskryje@email.cz<br></span>ičo: 48054500<br>"}>
            </Text>

            <Button className="btn-box btn-box--center ff--3 fs--24 w--500 ls--12" style={{"backgroundColor":"rgba(201,198,217,1)"}} content={"<span style=\"text-decoration-line: underline; font-weight: bold;\">objednat</span>"} url={"https://objednavka.lodeskryje.cz/"} href={"https://objednavka.lodeskryje.cz/"}>
            </Button>

            <FullmapCover place={"Šlovice 9 půjčovna Martin Mach"}>
            </FullmapCover>

          </FullmapWrap>

        </Fullmap>


        <Fullmap name={"xkewbmxazpf"}>
          
          <FullmapWrap className="pb--20 pt--20">
            
            <Subtitle className="subtitle-box subtitle-box--center" content={"Šlovice 9, okr. Rakovník<br>"}>
            </Subtitle>

          </FullmapWrap>

          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <Column className="pb--16 pt--16" name={"wrfj2b512xd"} layout={"l23"}>
          
          <ColumnWrap className="column__flex el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":100}} src={"https://cdn.swbpg.com/t/1438/04fda49881e04b7993763d4ae7b8ae00_s=860x_.JPG"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/1438/04fda49881e04b7993763d4ae7b8ae00_s=350x_.JPG 350w, https://cdn.swbpg.com/t/1438/04fda49881e04b7993763d4ae7b8ae00_s=660x_.JPG 660w, https://cdn.swbpg.com/t/1438/04fda49881e04b7993763d4ae7b8ae00_s=860x_.JPG 860w, https://cdn.swbpg.com/t/1438/04fda49881e04b7993763d4ae7b8ae00_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/1438/04fda49881e04b7993763d4ae7b8ae00_s=2000x_.JPG 2000w"}>
              </Image>

              <Text className="text-box fs--16" content={"<a href=\"http://www.machondak-sport.cz/\">půjčovna lodí Martin Mach</a><a href=\"w\"><br></a>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":100}} src={"https://cdn.swbpg.com/t/1438/447bf994a5aa48f781457c68ce85aef1_s=860x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/1438/447bf994a5aa48f781457c68ce85aef1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/1438/447bf994a5aa48f781457c68ce85aef1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/1438/447bf994a5aa48f781457c68ce85aef1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/1438/447bf994a5aa48f781457c68ce85aef1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/1438/447bf994a5aa48f781457c68ce85aef1_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box fs--16" content={"<a href=\"https://pivni-samoobsluha-slovice.business.site/?utm_source=gmb&amp;utm_medium=referral\">Pivní samoobsluha Šlovice</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"qrferf70fg"} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/1438/33c87f60790e478aaef57c7f2d345a22_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/1438/33c87f60790e478aaef57c7f2d345a22_s=350x_.jpg 350w, https://cdn.swbpg.com/t/1438/33c87f60790e478aaef57c7f2d345a22_s=660x_.jpg 660w, https://cdn.swbpg.com/t/1438/33c87f60790e478aaef57c7f2d345a22_s=860x_.jpg 860w, https://cdn.swbpg.com/t/1438/33c87f60790e478aaef57c7f2d345a22_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/1438/33c87f60790e478aaef57c7f2d345a22_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box text-box--center fs--20 w--300 mt--06" content={"<span style=\"color: rgb(4, 0, 0);\">kemp Višňová</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"jlzv3frgh9b"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"<span style=\"color: rgb(3, 2, 0); font-style: italic; font-weight: bold;\">informace</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--left" content={"<br><br><br>.Konečná cena je za plavidlo včetně vybavení Žádná záloha ani kauce, zrušení bez storno poplatku, místo ukončení plavby lze po dohodě změnit. Pro rodiny s dětmi nabízíme trojmístné kánoe a rafty. Osobní přístup k zákazníkům. Doprava vybavení zahrnuta v ceně.\n      Půjčujeme celoročně, cena za půjčení kánoe, raftu nebo paddleboardu je dle počtu dní, doprava vybavení v daném úseku zahrnuta v ceně - žádná záloha ani kauce - pojištění proti neúmyslnému poškození v ceně, storno bez poplatku. Naše půjčovna lodí na řece Berounce nabízí od roku 2010 zapůjčení veškerého vybavení pro vodáky v úseku Chrást u Plzně - Račice. Nachází se 15 km od Křivoklátu v obci Šlovice, poblíž známých Skryjí.                                  Tel. 777 083 538"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"999pwphr6ks"}>
        </Column>

      </Layout>
    </ThemeWrapper>
  )
}
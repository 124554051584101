export default {
  "cr6jmhtsgae": {
    "id": 13366,
    "temp_key": "cr6jmhtsgae",
    "site_id": 1438,
    "name": "",
    "items": [
      {
        "id": "v1mpbxhirr2x",
        "name": "Kontakt",
        "page": "/",
        "section": "vse93sn2sd",
        "use": "page",
        "elements": []
      }
    ]
  }
}